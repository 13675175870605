ul,
ol {
  margin: 0;
  padding: 0;
}

.list {
  &.list-no-style {
    padding-left: 0;
    list-style-type: none;

    &.password-list {
      line-height: 18px;
    }
  }
}

.ordered-list {
  list-style-type: none;
  text-indent: -21px; // prevent text from wrapping under number

  li {
    counter-increment: step-counter;

    &::before {
      color: #566171;
      content: counter(step-counter);
      font-size: $default-font-size;
      margin-right: 10px;
    }
  }
}

.list,
.ordered-list {
  max-width: 700px;
  padding-left: 25px;
  margin-bottom: $default-margin-bottom;

  li {
    margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  }
}

.selection-list {
  padding: 0;
  list-style-type: none;
  max-width: 700px;
  width: 100%;

  li {
    padding: 6px 22px;
    color: $smugglers-cove;
    background-color: transparent;
    @include transition(all 0.2s linear);

    a {
      display: block;
    }

    a,
    i {
      color: $smugglers-cove;

      &:hover,
      &.selected,
      &:focus {
        color: $smugglers-cove;
        text-decoration: none;
      }
    }

    &:hover,
    &.selected,
    &:focus,
    &.active {
      background-color: $light-steel-blue;
      color: $smugglers-cove;
      cursor: pointer;

      i {
        color: $smugglers-cove;
      }
    }

    &.disabled,
    &.disabled i,
    &.disabled a {
      color: $silver-chalice;
      background-color: transparent;
      cursor: not-allowed;
    }

    &.tooltip {
      border-bottom: none;
    }
  }
}

.pills {
  list-style-type: none;
  display: table;
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;

  &.pills-inline {
    display: inline;
    vertical-align: middle;

    .pills-pill {
      clear: none;
    }
  }
}

.pills-pill-vertical-center {
  margin: 0 5px !important;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pills-pill {
  display: inline-block;
  padding: 0 5px;
  font-size: 14px;
  margin: 2px 5px 2px 0;
  line-height: 20px;
  background-color: background('gray1');
  color: foreground('gray1');
  border-radius: $default-border-radius-small;
  float: left;
  clear: left;

  &.pills-badge {
    float: none;
    white-space: nowrap;
  }
}

.pills-pill-round {
  display: inline-block;
  padding: 4px 12px;
  font-size: 14px;
  margin: 2px 5px 2px 0;
  line-height: 20px;
  background-color: background('gray1');
  color: foreground('gray1');
  border-radius: 20px;
  clear: left;

  &.pills-round {
    white-space: nowrap;
  }
}

.pills-pill-close {
  margin-left: 4px;
  color: foreground('gray1');

  &::before {
    top: 2px;
  }

  @include icon(before, alert-close);
}

.pills-pill {
  @each $color-name in $color-set-tags {
    &.pills-pill-#{color-title($color-name)} {
      background-color: background($color-name);
      color: foreground($color-name);

      .pills-pill-close {
        color: foreground($color-name);

        &:hover {
          color: foreground($color-name);
        }
      }

      &.pills-pill-clear,
      &.pills-pill-with-border {
        border: 1px solid background($color-name);
      }

      &.pills-pill-clear {
        color: background($color-name);

        .pills-pill-close {
          color: background($color-name);

          &:hover {
            color: background($color-name);
          }
        }
      }
    }
  }

  &.pills-pill-clear {
    background-color: transparent;
  }
}

.pill-conjunction {
  color: $gun-powder;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}

.speaker-label {
  line-height: 18px;
}

.selection-list--with-avatar {
  padding: 0;
  list-style-type: none;
  width: 100%;

  // the create account link is displaying the text
  // at the bottom of the parent element. This fixes the
  // issue and centers the content vertically.
  .create-account {
    display: flex;
    align-items: center;
  }

  input[type='text'] {
    margin-left: 14px;
  }

  a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 6px 0 6px 14px;
    display: block;
    @include rhythm(16px, 24px);
    color: $mine-shaft;
    box-sizing: border-box;
    position: relative;

    &:hover {
      color: $mine-shaft;
      text-decoration: none;
      font-weight: bold;
    }

    .avatar.avatar--square {
      margin-right: 12px;
    }
  }

  .selection-list--active::before {
    content: ' ';
    display: block;
    position: absolute;
    width: 4px;
    height: 24px;
    background: $attribluetion;
    left: 0;
    border-radius: 2px;
    top: 10px;
  }
}

/**
Checkmark List
 */

@function uri-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

.checkmark-list {
  li {
    line-height: 1.5;
    margin-bottom: 0;
    padding-left: 1.5em;
    position: relative;
    list-style: none;
  }

  li::before {
    content: '\e90e';
    font-family: 'callrail';
    color: var(--color-attribluetion);
    font-size: 1em;
    font-weight: 600;
    position: absolute;
    margin-left: -1.6em;
  }
}

.white-label {
  .selection-list {
    li {
      color: $mine-shaft;

      &:hover,
      &.selected,
      &:focus,
      &.active {
        background-color: $dark-solitude;
        color: $mine-shaft;

        i {
          color: $mine-shaft;
        }
      }

      a,
      i {
        color: $mine-shaft;

        &:hover,
        &.selected,
        &:focus {
          color: $mine-shaft;
        }
      }
    }
  }

  .selection-list--with-avatar {
    a {
      color: $mine-shaft;

      &:hover {
        color: $mine-shaft;
      }
    }

    .selection-list--active::before {
      background: $mine-shaft;
    }
  }
}
